// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import "prismjs/themes/prism.css"
import "./src/styles/global.css"

function refreshScripts() {
  if (
    window.fd &&
    document.getElementById("fd-form-5eb81c46b9c153002b5ac626")
  ) {
    window.fd("form", {
      formId: "5eb81c46b9c153002b5ac626",
      containerEl: "#fd-form-5eb81c46b9c153002b5ac626",
    })
  }

  if (
    window.fd &&
    document.getElementById("fd-form-5ed511e1df06e70026d0a893")
  ) {
    window.fd("form", {
      formId: "5ed511e1df06e70026d0a893",
      containerEl: "#fd-form-5ed511e1df06e70026d0a893",
    })
  }
}

export const onRouteUpdate = () => {
  setTimeout(function () {
    refreshScripts()
  }, 1500)
}
