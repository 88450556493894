// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-craft-js": () => import("./../../../src/pages/portfolio/craft.js" /* webpackChunkName: "component---src-pages-portfolio-craft-js" */),
  "component---src-pages-portfolio-design-js": () => import("./../../../src/pages/portfolio/design.js" /* webpackChunkName: "component---src-pages-portfolio-design-js" */),
  "component---src-pages-portfolio-drink-js": () => import("./../../../src/pages/portfolio/drink.js" /* webpackChunkName: "component---src-pages-portfolio-drink-js" */),
  "component---src-pages-portfolio-editorial-js": () => import("./../../../src/pages/portfolio/editorial.js" /* webpackChunkName: "component---src-pages-portfolio-editorial-js" */),
  "component---src-pages-portfolio-events-js": () => import("./../../../src/pages/portfolio/events.js" /* webpackChunkName: "component---src-pages-portfolio-events-js" */),
  "component---src-pages-portfolio-food-js": () => import("./../../../src/pages/portfolio/food.js" /* webpackChunkName: "component---src-pages-portfolio-food-js" */),
  "component---src-pages-portfolio-gardening-js": () => import("./../../../src/pages/portfolio/gardening.js" /* webpackChunkName: "component---src-pages-portfolio-gardening-js" */),
  "component---src-pages-portfolio-history-js": () => import("./../../../src/pages/portfolio/history.js" /* webpackChunkName: "component---src-pages-portfolio-history-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-tech-js": () => import("./../../../src/pages/portfolio/tech.js" /* webpackChunkName: "component---src-pages-portfolio-tech-js" */),
  "component---src-pages-services-cost-js": () => import("./../../../src/pages/services/cost.js" /* webpackChunkName: "component---src-pages-services-cost-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-video-content-js": () => import("./../../../src/pages/services/video-content.js" /* webpackChunkName: "component---src-pages-services-video-content-js" */),
  "component---src-pages-services-video-production-js": () => import("./../../../src/pages/services/video-production.js" /* webpackChunkName: "component---src-pages-services-video-production-js" */),
  "component---src-pages-services-why-video-js": () => import("./../../../src/pages/services/why-video.js" /* webpackChunkName: "component---src-pages-services-why-video-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

